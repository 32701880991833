.ImageView {
  & > img {
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .fullscreenImage {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);

    position: fixed;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image-escape {
      align-self: flex-start;
      margin-left: 1em;
      background: none;
      border: none;
      color: white;
      font-weight: bold;
      font-size: 3em;

      &:hover {
        cursor: pointer;
      }
    }

    img {
      object-fit: contain;
      width: 90%;
      height: 80vh;

      &:hover {
        cursor: pointer;
      }
    }

    .image-description {
      color: white;

      a {
        color: #95caf6 !important;
      }

      font-style: italic;
      width: 50%;
      padding: 1em;
      line-height: initial;

      p {
        font-size: 1.2rem;
        font-family: "Merriweather", serif;
        font-style: italic;
      }
    }
  }
}

.mobile {
  .ImageView .fullscreenImage {
    justify-content: initial;
    img {
      margin-top: 1em;
    }

    .image-description {
      width: 90%;
    }

    .image-escape {
      display: none;
    }

    img {
      height: fit-content;
    }
  }
}

.Project {
  &:not(:last-child) {
    border-bottom: 6px #001f39 solid;
  }

  margin-top: 2em;
  padding-bottom: 2em;

  .title {
    font-weight: bold;
    margin-bottom: 0.2em;
  }

  .subtitle {
    margin-bottom: 0.5em;

    .copy-link {
      cursor: pointer;

      &:hover {
        filter: invert(55%) sepia(82%) saturate(3760%) hue-rotate(186deg)
          brightness(97%) contrast(91%);
      }
    }
  }

  .description {
    line-height: 1.2em;
  }

  .previewImage {
    margin-bottom: 2em;
  }

  a {
    color: #2492ee !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

.Project.large {
  .previewImage {
    width: 100%;
  }

  .description {
    margin-bottom: 1em;
  }
}

.Project.small {
  display: flex;
  align-items: center;

  .projectContent {
    order: 2;
  }

  .ImageView {
    max-width: 50%;
  }

  &.center {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    .description {
      margin-bottom: 1em;
    }

    .ImageView {
      order: 2;
      margin-right: 1em;
    }
  }

  &.left {
    text-align: left;

    .ImageView {
      order: 1;
      margin-right: 1em;
    }
  }

  &.right {
    text-align: right;

    .ImageView {
      order: 3;
      margin-left: 1em;
    }
  }
}

.mobile {
  .Project.small {
    flex-direction: column;
    align-items: center;
    text-align: initial;

    &.left {
      .ImageView {
        margin-bottom: 1em;
      }
    }

    &.right {
      .ImageView {
        margin-top: 1em;
      }
    }
  }
}

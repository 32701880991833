.CodingOverlay {
  margin-left: 15%;
  margin-top: 5em;
  margin-bottom: 5em;
  width: 45%;
  min-width: 625px;
  background: #f8f8f8;
  padding-bottom: 0px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
  border-radius: 1px;

  > .heading {
    border-radius: 1px;
    background: #001f39;
    color: white;
    padding: 1.5em 2em;

    .title {
      font-weight: bold;
      line-height: 1.3em;
    }

    .subtitle {
      font-weight: 700;
      color: #4fbfce;
      margin-top: 0.5em;
      font-size: 1.3em;

      .to {
        font-weight: 500;
        font-style: italic;
      }
    }
  }

  .projects {
    padding: 2em;
    padding-bottom: 0;
  }

  .attributionContainer {
    display: flex;
    width: 100%;

    .grow {
      flex-grow: 1;
    }

    .attribution {
      padding: 1em;
    }
  }
}

.mobile {
  .CodingOverlay {
    margin: auto;
    margin-top: 6em;
    margin-bottom: 0;
    width: 90%;
    min-width: 0px;

    > .heading {
      padding: 1em 1em;

      .title {
        line-height: 1.1em;
      }

      .subtitle {
        font-weight: 700;
        color: #4fbfce;
        margin-top: 0.5em;
        font-size: 1.1em;
      }
    }

    .projects {
      padding: 1em;
      padding-bottom: 0;
    }
  }
}

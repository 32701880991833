.MapElement {
  z-index: -1;
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.mobile {
  .MapElement {
    transform: scale(0.9);

    &.StationMarker {
      transform: scale(0.7);
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.AboutMeOverlay {
  margin: auto;
  margin-top: 5em;
  margin-bottom: 5em;
  padding: 0.5em;
  width: 35%;
  min-width: 500px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);

  .heading {
    background: #001f39;
    width: 100%;
    color: white;
    text-align: center;

    h4,
    h5 {
      font-weight: bold;
      padding: 0.4em 0;
    }

    h4 {
      font-size: 1.8em;
    }
    h5 {
      font-size: 1.2em;
    }

    &.main {
      margin-top: 0.5em;
      text-align: left;
      padding-left: 0.5em;
    }
  }

  p {
    margin: 1em 0;
    line-height: 1.3em;
  }

  a {
    color: #2492ee !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

.mobile {
  .AboutMeOverlay {
    margin-top: 6em;
    width: 90%;
    min-width: 0px;
  }
}

@use "reset.css";
@use "links.scss";

@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital@1&display=swap");

html {
  background: #f9f0e8;
  color: #001f39;
  font-family: Helvetica Neue;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

h1 {
  font-size: 5.61rem;
}
h2 {
  font-size: 4.209rem;
}
h3 {
  font-size: 3.157rem;
}
h4 {
  font-size: 2.369rem;
}
h5 {
  font-size: 1.777rem;
}
h6 {
  font-size: 1.333rem;
}
p {
  font-size: 1rem;
}
small {
  font-size: 0.75rem;
}

.mobile {
  h1 {
    font-size: 3.73rem;
  }
  h2 {
    font-size: 2.94rem;
  }
  h3 {
    font-size: 2.17rem;
  }
  h4 {
    font-size: 1.66rem;
  }
  h5 {
    font-size: 1.249rem;
  }
  h6 {
    font-size: 1.1rem;
  }
  p {
    font-size: 1rem;
  }
  small {
    font-size: 0.6rem;
  }
}

.Page {
  width: 100vw;
  min-height: 100vh;

  overflow: hidden;
  position: absolute;
}

.mobile {
  .Page {
    min-height: 105vh;
    padding-bottom: 5vh;
  }
}

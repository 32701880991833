a {
  text-decoration: none;
  color: black;
}

a:visited {
  color: black;
}

// Used for gradient underlines
.hover-underline {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 3px;
  width: 100%;
  background: transparent;
}

.ContactOverlay {
  margin-left: 30%;
  margin-top: 5em;
  margin-bottom: 5em;
  width: 45%;
  min-width: 550px;
  background: #f8f8f8;
  padding-bottom: 0px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
  border-radius: 1px;
  max-width: 600px;

  .header {
    padding: 0.5em;
    padding-bottom: 3em;

    background-color: #000000;
    color: white;

    display: flex;
    justify-content: space-between;
    align-items: start;

    h1 {
      font-size: 2em;
      font-weight: bold;
    }

    .jtaLogo {
      width: 35px;
      height: 35px;
    }
  }

  .services {
    padding: 1em;
    min-height: 70vh;

    .content {
      margin-bottom: 1em;
    }

    .service {
      margin-bottom: 1.5em;
    }

    .line {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }

    .codeAndDesign {
      font-weight: bold;
      font-size: 2.5em;
      margin-bottom: 0.3em;
    }

    .subtitleContainer {
      width: 100%;
      border-bottom: 1px solid black;
      margin-bottom: 10px;

      .subtitle {
        margin-bottom: 10px;
        margin-top: 0.5em;
        font-weight: bold;
      }
    }
  }

  .footer {
    padding: 0.5em;
    background-color: #e7e7e7;
    display: flex;
    justify-content: space-between;

    .text-block {
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;

      small {
        margin-bottom: 2px;
      }
    }

    small {
      font-size: 5px;
    }
  }
}

.ContactService {
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 4.1px;

  a {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 3px;
      width: 24px;
      height: 24px;
    }

    .handle {
      font-weight: 500;
    }

    &:hover {
      .handle {
        text-decoration: underline;
      }
    }
  }
}

.mobile {
  .ContactOverlay {
    margin: auto;
    margin-bottom: 0;
    margin-top: 6em;
    width: 90%;
    min-width: 0px;

    .services {
      line-height: 1.5em;
    }
  }

  .ContactService {
    padding-bottom: 1.9px;
  }
}
